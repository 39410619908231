import React from 'react'
import { CiFacebook} from "react-icons/ci";
import {VscGithubAlt} from "react-icons/vsc";
import {FiLinkedin, FiYoutube} from "react-icons/fi";
import { Link } from 'react-router-dom';

function Footer(props) { 
  
  const SocialLinks = [
    {name:'', icon:<CiFacebook/> , link:'https://www.facebook.com/kiosso.camuegi.71/'},
    {name:'', icon:<VscGithubAlt/> , link:'https://kiossocamuegi.github.io/'},
    {name:'', icon:<FiLinkedin /> , link:'https://www.linkedin.com/in/kiosso-camuegi-564b79211/'}, 
    {name:'', icon:<FiYoutube/> , link:'#'}
]

  return (
    <div id='contacts' className='footer' style={{background:`${props.color ? props.color : 'var(--pm-color)'}`,
    backgroundBlendMode:`${props.blend ? props.blend : ''}` }}>
      <div className="wrapper">
      <div className="info">
      <div className="space">
        <div className="title text-right">
             <h2>05. <span>Contact-me</span></h2>
         </div>
        <Link to="/resume"><div className="link">Open Resume</div></Link>
        </div>
             <div className="social-links">
                 <ul>
                   {SocialLinks.map((item, index)=>{
                      return ( 
                        <a key={index} href={item.link}  rel="noreferrer" alt={item.name} target='_blank'>
                         <li> 
                            {item.icon} 
                          </li>
                        </a>)
                    })}
                 </ul>
             </div>
      </div>
      </div>
       <div className="footer-down" style={{background:'#fff'}}>
          <div className="wrapper">
             <div className="contacts">
                 <div className="box"> kiossodevelover@gmail.com</div>
                 <div className="box">+351 9** ** *** </div>
                 <div className="box">kiossocamuegi.pages.dev</div>
             </div>
          </div>
       </div>
    </div>
  )
} 
export default Footer